import React from 'react'
import {graphql} from 'gatsby'
import "styles/style.scss"

// import { siteMetadata } from '../../gatsby-config'
import {Layout, EmailForm} from 'components'
import {Page} from 'types'
import jsonData from 'data'

const ContactCard = (
  {className} : {className? : string}
  ) => (
  <div className={className}>
      Jim Germain
      <br></br>
      <small>Owner</small>
      <small>
        <br></br>
        <span>3</span>
        <span>0</span>
        <span style={{display:"none"}} >hello</span>
        <span>6</span>
        <span>-</span>
        <span>2</span>
        <span>3</span>
        <span>0</span>
        <span>-</span>
        <span>1</span>
        <span style={{display:"none"}}>publications</span>
        <span>6</span>
        <span>2</span>
        <span>1</span>
        <br></br>
        <span>r</span>
        <span>e</span>
        <span style={{display:"none"}}>hello</span>
        <span>p</span>
        <span>l</span>
        <span>a</span>
        <span>y</span>
        <span>@</span>
        <span>s</span>
        <span>a</span>
        <span style={{display:"none"}}>publications</span>
        <span>s</span>
        <span>k</span>
        <span>t</span>
        <span>e</span>
        <span>l</span>
        <span>.</span>
        <span>n</span>
        <span style={{display:"none"}}>partners</span>
        <span>e</span>
        <span>t</span>
      </small>
  </div>
)

export default ({data}) => {
  data.site.siteMetadata.title = `${data.site.siteMetadata.title} | Contact`
  data.site.siteMetadata.siteUrl = `${data.site.siteMetadata.siteUrl}/contact`

  // useEffect(() => {
  //     window.scrollTo(0,0)
  // }, [data])

  return (
      <Layout siteMetadata={data.site.siteMetadata} page={Page.contact}>
          
            <div className='page'>
              <div className='row mb-5 mx-auto'>
                <div className='col-md-8 my-md-0 my-5 mx-auto'>
                  <div className='row align-items-center my-5'>
                    <div className='col-md-8 col-11 mx-auto'><p className='text-center'>{ jsonData.text.contact }</p></div>
                    <div className='col-md-4'><ContactCard className='contactCard my-3 p-3' /></div>
                  </div>
                  <div>
                    <div className='col-11 m-auto' >
                      <EmailForm page={Page.contact} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
      </Layout>
    )
}

export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        author
        authorDescription
        description
        title
        themeColor
        siteUrl
      }
    }
  }
`
